import { PROPOSAL_TYPES } from '../types/proposals.types';
import { SPAProposalsState } from '../types/spaProposals.types';

export const SPA_PROPOSAL_ESCORT_TYPE_AGE: number = 6;

export const MAX_LENGTH_TEXTAREA: number = 1999;

export const MIN_LENGTH_EPICRISIS: number = 160;

export const MIN_LENGTH_ANAMNESE: number = 300;

export const PROPOSAL_STATES: { label: string; value: string }[] = [
  {
    label: 'Schválený',
    value: 'S',
  },
  {
    label: 'Neschválený',
    value: 'N',
  },
  {
    label: 'Na doplnenie',
    value: 'D',
  },
  {
    label: 'Spracováva sa',
    value: 'E',
  },
];

export const SPA_TREATEMENT_STATES: { label: string; value: string }[] = [
  {
    label: 'Prebehla',
    value: 'prebehla',
  },
  {
    label: 'Neprebehla',
    value: 'neprebehla',
  },
  {
    label: 'Prebieha',
    value: 'prebieha',
  },
];

export const SPA_INSUREE_VZ: { label: string; value: string }[] = [
  {
    label: 'Zmena zdravotnej poisťovne',
    value: 'K',
  },
  {
    label: 'Exitus',
    value: 'U',
  },
];

// Initial Redux State
export const spaProposalsInitialState: SPAProposalsState = {
  detail: {
    data: null,
    documents: {
      data: null,
      error: null,
      isLoading: false,
    },
    error: null,
    isLoading: false,
  },
  list: {
    approvedProposals: {
      data: [],
      error: null,
    },
    isLoading: false,
    queryParams: null,
    storedFilter: null,
    submittedProposals: {
      data: [],
      error: null,
    },
    tableOrder: {
      key: '',
      order: 'asc',
    },
  },
  new: {
    data: {
      controls: {
        data: [],
        error: '',
        errorInsuree: '',
        isLoadedTXSControls: false,
        isLoadingControl: null,
        payload: {
          kontrolaTypy: [],
          rodneCislo: '',
        },
      },
      doctors: {
        data: null,
        error: null,
        isLoading: false,
      },
      formData: {
        data: null,
        error: null,
        isLoading: false,
      },
    },
    isReseted: false,
    save: {
      data: null,
      error: null,
      isLoading: false,
    },
    stepper: {
      activeStep: 0,
      step1: {
        ambulance: {
          code: '',
          expertise: '',
          id: 0,
          name: '',
        },
        doctor: {
          code: '',
          email: '',
          expertise: '',
          id: 0,
          name: '',
        },
        stepStatus: null,
      },
      step2: {
        insureeId: '',
        poiData: null,
        stepStatus: null,
      },
      step3: {
        diagnose: null,
        eventDate: '',
        showCancelBtnAfter: null,
        indicatorGroup: null,
        reasonDescription: '',
        spaConfirmation: null,
        stepStatus: null,
        spaContraindications: null,
        spaExaminations: null,
        spaSVLZ: null,
      },
      step4: {
        description: '',
        diagnoses: [],
        stepStatus: null,
      },
      step5: {
        escort: null,
        helpingEscort: '',
        mobility: null,
        spaType: null,
        stepStatus: null,
      },
    },
  },
};

// Mocked Redux State
export const spaProposalsMockedState: SPAProposalsState = {
  ...spaProposalsInitialState,
  new: {
    ...spaProposalsInitialState.new,
    data: {
      ...spaProposalsInitialState.new.data,
      doctors: {
        ...spaProposalsInitialState.new.data.doctors,
        data: [
          {
            ambulancie: [
              {
                idNZ: 1,
                kodNZ: '123',
                nazovNZ: 'Vseobecne lekarstvo',
                odbornostNZ: '020',
                typUvazku: 'A',
              },
            ],
            email: 'test@dovera.sk',
            idLekarZdravPrac: 1,
            kodLekara: '020',
            menoLekara: 'MUDr. Mrkvicka Jozef',
            odbornostLekara: '020',
          },
        ],
      },
      formData: {
        ...spaProposalsInitialState.new.data.formData,
        data: {
          dovodySprievodu: [],
          udajDetIndSkup: {
            diagnozy: '',
            epikriza: 'Testik epikrizy',
            id: 1,
            kodIndikacnaSkupina: '124',
            kodMieraUhradyZS: '543',
            kodProduktPZS: '12',
            kontraIndikacie: '',
            nazov: 'Test',
            objektivnyNalez: '',
            pocetDniKupel: 21,
            trvanieKupel: 3,
            vekDo: 99,
            vekOd: 18,
          },
          udajParUdalIndSkup: {
            drgVykony: '',
            id: 1,
            kodIndikacnaSkupina: '124',
            kodTypCasjednotky: 5,
            perioda: 1,
            udalost: 'Hospitalizacia',
            udalostDo: '2022-05-03',
            udalostOd: '2022-04-03',
          },
        },
      },
    },
    resetAfterFilled: undefined,
    stepper: {
      ...spaProposalsInitialState.new.stepper,
      activeStep: 2,
      step1: {
        ambulance: {
          code: 'P1235526269',
          expertise: '020',
          id: 1,
          name: 'Vseobecne lekarstvo',
        },
        doctor: {
          code: '123',
          email: 'test@dovera.sk',
          expertise: '020',
          id: 1,
          name: 'MUDr. Mrkvicka Jozef',
        },
        stepStatus: 'completed',
      },
      step2: {
        insureeId: '8956128742',
        poiData: {
          address: 'Moldavska 10',
          dateOfBirth: '1989-05-12',
          firstName: 'Jozko',
          lastName: 'Mrkvicka',
          mobile: '+421901123456',
          sex: 'M',
        },
        stepStatus: 'completed',
      },
      step3: {
        diagnose: {
          kod: '123',
          nazov: 'Diagnoza',
          kodSkupinaDiagnoz: 11,
        },
        eventDate: '2022-08-20',
        indicatorGroup: {
          kod: '124',
          nazov: 'IS',
          kodProdukt: '12',
        },
        reasonDescription: 'Test epikrizy...',
        showCancelBtnAfter: null,
        spaConfirmation: 'Y',
        spaContraindications: 'N',
        spaExaminations: 'Y',
        spaSVLZ: 'Y',
        stepStatus: 'completed',
      },
      step4: {
        description: 'test',
        diagnoses: [
          {
            kod: '123',
            nazov: 'Test',
          },
        ],
        stepStatus: 'completed',
      },
    },
  },
};

export const SUBMITTED_POPOSALS_TABS: PROPOSAL_TYPES[] = [
  PROPOSAL_TYPES.SPA,
  PROPOSAL_TYPES.Drugs,
  PROPOSAL_TYPES.CNP,
];
