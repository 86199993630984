import { Button, Notification } from '@dovera/design-system';
import { StepWrapper } from '../../common';
import Summary from './Summary';
import strings from '../../../../../constants/strings';
import ProductCode from './ProductCode/ProductCode';
import { useField, useForm, useFormState } from 'react-final-form-hooks';
import { validationMsg } from '../../../../../utils/form.utils';
import { VALIDATION } from '../../../../../types/validation.types';
import { PROPOSAL_TYPES } from '../../../../../types/proposals.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { changeActiveStep } from '../../../../../slices/drugProposals.slice';
import Diagnose from './fields/Diagnose';
import ReasonAttachment from './fields/ReasonAttachment';
import Contraindication from './fields/Contraindication';
import MinisterException from './fields/MinisterException';
import DateFrom from './DateFrom/DateFrom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { getFormData } from '../../../../../api/drugProposals';
import { canShowField } from '../../../../../utils/drugProposals.utils';
import {
  DrugCnpProposalData,
  DrugProposalField,
} from '../../../../../types/drugProposals.types';
import {
  getFirstMatch,
  isSqlInjection,
  removeLineBreaks,
  replaceNewLineChars,
} from '../../../../../utils/strings.utils';
import QuantityDosage from './fields/QuantityDosage';
import { MIN_LENGTH_EPICRISIS } from '../../../../../constants/proposals';
import regex from '../../../../../constants/regex';
import { Dokument } from '../../../../../types/models/Dokument';
import { LoadingState } from '../../../../../types';
import { systemConstants } from '../../../../../constants/systemConstants';
import { validation } from '../../../../../constants/validation';
import { CUSTOM_DOSAGE } from '../../../../../constants/drugProposals';

interface Props {
  onContinue: (files: Dokument[] | null) => void;
}

const validate = (
  files: Dokument[] | null,
  values: { [x: string]: any },
  cnpData?: DrugCnpProposalData,
) => {
  const errors: any = {};
  const textareaControls: { field: string; onlyBaseControls?: boolean }[] = [
    { field: 'epicrisis' },
    { field: 'reason' },
    { field: 'dosageDescription', onlyBaseControls: true },
  ];

  if (!values.product) errors.product = validationMsg(VALIDATION.ProductEmpty);
  if (!values.diagnose)
    errors.diagnose = validationMsg(VALIDATION.DiagnoseEmpty);
  if (!values.dateFrom)
    errors.dateFrom = validationMsg(VALIDATION.RequiredEmpty);
  if (!values.quantity)
    errors.quantity = validationMsg(VALIDATION.RequiredEmpty);
  if (values.quantity && values.quantity < 1)
    errors.quantity = validationMsg(VALIDATION.GreaterThanZero);
  if (
    values.quantity &&
    cnpData?.mnozstvo &&
    Number(values.quantity) > Number(cnpData?.mnozstvo)
  )
    errors.quantity = validation[VALIDATION.ProductQuantity](
      Number(cnpData?.mnozstvo),
    );
  if (
    values.quantity &&
    Number(values.quantity) > systemConstants.MAX_INPUT_INT
  )
    errors.quantity = validationMsg(VALIDATION.MaxIntValue);
  if (values.quantity && values.quantity % 1 !== 0)
    errors.quantity = validationMsg(VALIDATION.OnlyInteger);
  if ((!values.epicrisis || !values.reason) && !files?.length) {
    errors.epicrisis = !values.epicrisis ? ' ' : '';
    errors.reason = !values.reason ? ' ' : '';
  }
  textareaControls.forEach((control) => {
    const { field, onlyBaseControls } = control;
    if (
      values[field] &&
      (!replaceNewLineChars(values[field]) ||
        !values[field].replace(/\s+/gi, '').replace(/\n+/gi, ''))
    )
      errors[field] = texts.inputErrors.incorrectValue;
    if (
      values[field] &&
      !onlyBaseControls &&
      values[field].length < MIN_LENGTH_EPICRISIS
    )
      errors[field] = texts.inputErrors.epicrisisLength(
        field === 'reason' ? 'zdôvodnenie liečby' : 'epikríza',
      );
    if (values[field] && isSqlInjection(values[field]))
      errors[field] = validationMsg(
        VALIDATION.SqlInjection,
        getFirstMatch(values[field], regex.SQL_INJECTION),
      );
  });

  return errors;
};

const texts = strings.proposals.drugs.new;
const commonTexts = strings.proposals.common;

const Step3 = ({ onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<Dokument[] | null>(null);
  const [isUploadError, setIsUploadError] = useState(false);
  const {
    data: {
      controls,
      formData: { data, dataState, error, formId },
    },
    save,
    stepper: { activeStep, step1, step2, step3 },
  } = useSelector((state: RootState) => state.drugProposals.new);
  const { form, handleSubmit } = useForm({
    initialValues: {
      dateFrom: '',
      product: '',
      diagnose: '',
      indicativeLimit: '',
      paymentPermission: undefined,
      contraindication: undefined,
      quantity: undefined,
      dosage: CUSTOM_DOSAGE,
    },
    onSubmit: () => {
      onContinue(files);
    },
    validate: (values) => validate(files, values, data?.udajParLiekNavrhuCnp),
  });
  const formState = useFormState(form);
  const productField = useField('product', form);
  const dgField = useField('diagnose', form);
  const permissionField = useField('paymentPermission', form);
  const indicativeLimitField = useField('indicativeLimit', form);
  const contraindicationField = useField('contraindication', form);
  const epicrisisField = useField('epicrisis', form);
  const quantityField = useField('quantity', form);
  const dosageField = useField('dosage', form);
  const dosageDescriptionField = useField('dosageDescription', form);
  const reasonField = useField('reason', form);
  const dateFromField = useField('dateFrom', form);

  const getIndicatorLimits = (): { label: string; value: string }[] | null => {
    if (!data?.udajIndikacnehoObmedzenia?.length) return null;
    return (
      data.udajIndikacnehoObmedzenia.map((item) => ({
        label: removeLineBreaks(
          `${item.kodObmedzenia} - ${item.nazovObmedzenia}`,
        ),
        value: item.kodObmedzenia || '',
      })) || null
    );
  };

  const getAutocompleteIndicatorLimits = ():
    | { label: string; value: string }[]
    | null => {
    if (!data?.udajIndikacnehoObmedzenia?.length) return null;
    return (
      data.udajIndikacnehoObmedzenia
        .filter(
          (item) =>
            !indicativeLimitField.input.value ||
            removeLineBreaks(`${item.kodObmedzenia} - ${item.nazovObmedzenia}`)
              .toLowerCase()
              .includes(indicativeLimitField.input.value.toLowerCase()),
        )
        ?.map((item) => ({
          label: removeLineBreaks(
            `${item.kodObmedzenia} - ${item.nazovObmedzenia}`,
          ),
          value: item.kodObmedzenia || '',
        })) || null
    );
  };

  /**
   * If step2 changed, reset step3 form fields
   */
  useEffect(() => {
    if (step2.insureeId) {
      form.reset();
      Object.keys(formState.values).forEach((key) => {
        // @ts-ignore
        form.resetFieldState(key);
      });
    }
    // eslint-disable-next-line
  }, [step2.insureeId]);

  useEffect(() => {
    if (step3.product) {
      permissionField.input.onChange(undefined);
      indicativeLimitField.input.onChange('');
      contraindicationField.input.onChange(undefined);
    }
    // eslint-disable-next-line
  }, [productField.input.value, step3.product]);

  useEffect(() => {
    if (
      step1.stepStatus === 'completed' &&
      step2.stepStatus === 'completed' &&
      step3.diagnose &&
      step3.product &&
      step3.hasMV !== null &&
      formId
    ) {
      dispatch(
        getFormData({
          formularId: formId,
          kodDiagnoza: step3.diagnose.kod,
          kodProdukt: step3.product.code,
          maMV: step3.hasMV === 'Y',
        }),
      );
    }
  }, [
    dispatch,
    formId,
    step1,
    step2,
    step3.diagnose,
    step3.hasMV,
    step3.product,
  ]);
  const expertiseAmbulance = step1.ambulance.expertise;
  const expertiseDoctor = step1.doctor.expertise;
  const renderError =
    (dataState === LoadingState.error && error) ||
    (!!controls.error && (
      <div className="fit-content">
        <Notification message={error || controls.error} variant="error" />
      </div>
    ));

  const renderButton = !!quantityField.input.value &&
    canShowField(DrugProposalField.Reason) &&
    !quantityField.meta.error && (
      <>
        <div className="mb-small" />
        <Button
          className="no-mrg"
          isDisabled={isUploadError}
          isLoading={save.dataState === LoadingState.fetching}
          submit
        >
          {commonTexts.buttons.send}
        </Button>
      </>
    );
  return (
    <StepWrapper
      activeStep={activeStep}
      content={
        <form noValidate onSubmit={handleSubmit}>
          <ProductCode
            expertiseAmbulance={expertiseAmbulance}
            expertiseDoctor={expertiseDoctor}
            field={productField}
            onReset={() => {
              dgField.input.onChange('');
              indicativeLimitField.input.onChange('');
              form.resetFieldState('diagnose');
              form.resetFieldState('paymentPermission');
              form.resetFieldState('contraindication');
              form.resetFieldState('dateFrom');
              form.resetFieldState('quantity');
              form.resetFieldState('dosage');
            }}
          />
          {canShowField(DrugProposalField.Diagnose) && (
            <Diagnose
              field={dgField}
              onReset={() => {
                indicativeLimitField.input.onChange('');
              }}
            />
          )}
          {renderError}
          {canShowField(DrugProposalField.MinisterException) && (
            <MinisterException
              autocompleteIndicatorList={getAutocompleteIndicatorLimits()}
              fieldIndicativeLimit={indicativeLimitField}
              fieldRadio={permissionField}
              form={form}
              indicatorLimitCount={Number(
                data?.udajIndikacnehoObmedzenia?.length,
              )}
              indicatorList={getIndicatorLimits()}
            />
          )}
          {canShowField(DrugProposalField.Contraindication) && (
            <Contraindication field={contraindicationField} />
          )}
          {canShowField(DrugProposalField.DateFrom) && (
            <DateFrom field={dateFromField} />
          )}
          {canShowField(DrugProposalField.QuantityDosage) && (
            <QuantityDosage
              dosageDescriptionField={dosageDescriptionField}
              dosageField={dosageField}
              quantityField={quantityField}
              storedValues={{
                dosage: step3.dosage || '',
                dosageDescription: step3.dosageDescription || '',
                quantity: Number(step3.quantity),
              }}
            />
          )}
          {canShowField(DrugProposalField.Reason) && (
            <ReasonAttachment
              epicrisisField={epicrisisField}
              onUploadError={(isError) => setIsUploadError(isError)}
              onUploadFiles={(files) => setFiles(files)}
              reasonField={reasonField}
              storedValues={{
                epicrisis: step3.epicrisis || '',
                reason: step3.reason || '',
              }}
            />
          )}
          {renderButton}
        </form>
      }
      // eslint-disable-next-line
      changeActiveStep={changeActiveStep}
      step={3}
      stepStatus={step3.stepStatus}
      summary={<Summary />}
      title={texts.step3Title}
      type={PROPOSAL_TYPES.Drugs}
    />
  );
};

export default Step3;
